import axios from "@/axios/axios.js";

// 获取公告列表
export const getData = (data) => {
  return axios({
    url: "https://acs.indotek.ai/mars/admin-api/system/mail-template/send-single-mail",
    method: "post",
    data,
  });
};

// 话术音频试听（文字转音频）
export const getAudition = (query) => {
  return axios({
    url: "https://acs.indotek.ai/mars/callcenter/voice/audition",
    method: "get",
    params:query,
		responseType: 'blob',
		headers: {
		  'Area-id': '1',
			'tenant-id':'1719620740903153665'
		}
  });
};


// （音频转文字）
export const getConvert = (data) => {
  return axios({
    url: "https://acs.indotek.ai/mars/callcenter/voice/convert",
    method: "post",
    data,
  });
};
